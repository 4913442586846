<template>
   <div>
      <div class="body grey-bg">
         <div class="row">
            <div class="col-md-6 text-center align-self-center">
               <div>
                  <div class="titleCurso">
                     <h1><span>{{ $t('termosCompras.boasCompras') }}</span></h1>
                     <p>{{ $t('termosCompras.boasComprasDesc') }}</p>
                  </div>
               </div>
            </div>
            <div class="col-md-6 text-center">
               <img src="@/assets/admin/images/cleber.png" alt="Start image" width="300">
            </div>
         </div>
      </div>
      <div class="body">
         <div class="row">
            <div class="col-12">
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span><b>{{ $t('termosCompras.qualidade') }}: </b>{{ $t('termosCompras.qualidadeDesc') }}</span>
               </div>
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span><b>{{ $t('termosCompras.transparencia') }}: </b>{{ $t('termosCompras.transparenciaDesc') }}</span>
               </div>
               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span><b>{{ $t('termosCompras.seguranca') }}: </b>{{ $t('termosCompras.segurancaDesc') }}</span>
               </div>

               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span><b>{{ $t('termosCompras.atendimento') }}: </b>{{ $t('termosCompras.atendimentoDesc') }}</span>
               </div>

               <div class="mt-2 p-2">
                  <span class="bullet">&bull;</span>
                  <span><b>{{ $t('termosCompras.experiencia') }}: </b>{{ $t('termosCompras.experienciaDesc') }}</span>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
	name: 'TermosCompras',
}

</script>

<style scoped>
.titleCurso h1 {
	text-transform: uppercase;
	font-size: 50px;
	line-height: 70px;
	margin-bottom: 20px;
	font-weight: bold;
}

.titleCurso h1 span {
   color: #dd0f20;
}

.titleCurso p {
	font-weight: 400;
	font-size: 14px;
	padding-left: 20px;
	line-height: 28px;
	margin: 30px 0;
}

.body {
   padding-left: 10vw;
   padding-right: 10vw;
   padding-top: 5vw;
   padding-bottom: 5vw;
}

.title {
   margin-bottom: 10vh;
}
</style>